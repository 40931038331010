<template>
  <div class="entry">
    <div class="mask" v-if="show"></div>
    <img mode="widthFix" src="@/assets/images/bg.png" alt="" />
    <div class="text">趣恋情书 心动有你</div>
    <span class="tip">当前情书未设置 请用微信扫码设置</span>
  </div>
</template>

<script>
export default {
  name: "Entry",
  data() {
    return {
      UUID: "",
      show: true
    };
  },
  created() {
    var url = decodeURI(location.search);
    var queryList = {};
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        queryList[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      strs.forEach((query) => {
        queryList[query.split("=")[0]] = query.split("=")[1];
      });
    }
    const UUID = queryList.UUID;
    const WX = queryList.WX ? queryList.WX : 0;
    // const WX = 1; // 方便测试
    // const UUID = "testid4"; // 方便测试
    this.UUID = UUID;
    this.$bus.$emit("entrypage", UUID);
    console.log(UUID, WX);
    this.$cloud
      .callFunction({
        name: "router",
        data: {
          url: "status",
          params: {
            UUID,
          },
        },
      })
      .then((res) => {
        console.log(res);
        if (!res.result.data.isFirst) {
          this.$router.push({
            path: `finished/${WX}/${UUID}`,
          });
        } else if (+WX) {
          this.$router.push({
            path: `home/${UUID}`,
          });
        }else {
          this.show = false
        }
      });
  },
};
</script>

<style lang="scss" scoped>
.entry {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0;
    left: 0;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    z-index: -1;
  }
  .text {
    width: 70%;
    height: 80px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-top: 300px;
    // margin-bottom: 105px;
    font-family: PingFangSC-regular;
  }

  .tip {
    width: 235px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #797979;
    border: 1px solid #101010;
    text-align: center;
    border-radius: 16px;
    margin-top: 8px;
  }
}
</style>